// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-index-tsx": () => import("/opt/build/repo/src/pages/landing/index.tsx" /* webpackChunkName: "component---src-pages-landing-index-tsx" */),
  "component---src-pages-landing-polygon-tsx": () => import("/opt/build/repo/src/pages/landing/polygon.tsx" /* webpackChunkName: "component---src-pages-landing-polygon-tsx" */),
  "component---src-pages-menu-index-tsx": () => import("/opt/build/repo/src/pages/menu/index.tsx" /* webpackChunkName: "component---src-pages-menu-index-tsx" */),
  "component---src-pages-menu-nav-items-tsx": () => import("/opt/build/repo/src/pages/menu/navItems.tsx" /* webpackChunkName: "component---src-pages-menu-nav-items-tsx" */),
  "component---src-pages-works-carousel-tsx": () => import("/opt/build/repo/src/pages/works/carousel.tsx" /* webpackChunkName: "component---src-pages-works-carousel-tsx" */),
  "component---src-pages-works-index-tsx": () => import("/opt/build/repo/src/pages/works/index.tsx" /* webpackChunkName: "component---src-pages-works-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

